<template>
  <div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      persistent
      :width="default_opt.width"
      :height="default_opt.height"
    >
      <v-card
        :color="default_opt.bg_color"
      >
        <v-card-text class="pt-4">
          <slot :color="default_opt.color" name="title"><h5 color="white"> Please wait ...</h5></slot>
          <v-progress-linear
            indeterminate
            :color="default_opt.color"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'loading-dialog',
    props: {
        dialog: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
    data () {
      return {
        default_opt: {
          type: "circular",
          width: "300",
          height: "auto",
          bg_color: "primary",
          color: "white",
        }
      }
    },

    watch: {
      dialog (val) {
        if (!val) return
        //setTimeout(() => (this.dialog = false), 4000)
      }
    }
  }
</script>
