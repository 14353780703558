<template>
    <v-dialog persistent v-model="form_data.show" min-width="300" :max-width="600" @keydown.esc="$emit('dialog')">
        <v-form @submit.prevent="validateForm()" v-model="valid" ref="form" autofocus lazy-validation>
        <v-card class="rounded-card pa-0 ma-0" color="grey lighten-3">
        <!-- <v-toolbar dark color="grey lighten-3" class="d-flex justify-end align-end" dense flat>
            <v-toolbar-title class="black--text">
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-icon size="20px" class="black--text" v-on="on" @click.stop="()=>{ form_data.show = false }">close</v-icon>
                    </template>
                    <span>Tutup</span>
                </v-tooltip>
            </v-toolbar-title>
        </v-toolbar> -->
        <v-system-bar
            color="primary darken-3"
            dark
            class="pa-6 accent--text text--lighten-1"
          >
            <h3 ><b>Filter {{title ? title : 'Data'}}</b></h3>
            <v-spacer></v-spacer>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <!-- <v-icon size="20px" class="black--text" v-on="on" @click.stop="()=>{ form_data.show = false }">close</v-icon> -->
                    <v-btn color="primary" icon v-on="on" @click.stop="()=>{ form_data.show = false }"><v-icon>close</v-icon></v-btn>
                </template>
                <span>Tutup</span>
            </v-tooltip>
            
        </v-system-bar>
        <!-- <v-card-text :class="'title '+form_data.type+'--text'"><b>{{form_data.title}}</b></v-card-text> -->
        <v-card-text class="pt-8">
            
                <v-layout row wrap >
                    <v-flex xs12 md6 class="text-xs-center d-flex justify-center align-center px-4" v-for="(input,idx) in form_data.inputs" :key="'keyInput-'+input.value">
                        <!-- <label :for="input.value">{{input.label}}</label><br> -->
                        <v-autocomplete v-if="input.type == 'autocomplete'"
                            :autofocus="idx == 0 ? true : false"
                            :items="checkDefault(input.value)"
                            :label="input.label" :required="input.required"
                            outlined dense :placeholder="input.label"
                            v-model="form_data.inputData[input.value]"
                            :disabled="getDisabled(input.disabled)"
                            clearable
                            :rules="input.required ? not_null_rule : []"
                            :class="'white-bg '+ (input.required ? 'required' : '')"
                        ></v-autocomplete>
                        <v-select v-else-if="input.type == 'select'"
                            :autofocus="idx == 0 ? true : false"
                            :items="input.options"
                            :label="input.label" :required="input.required"
                            outlined dense :placeholder="input.label"
                            v-model="form_data.inputData[input.value]"
                            :rules="input.required ? not_null_rule : []"
                            clearable
                            :class="'white-bg '+ (input.required ? 'required' : '')"
                        ></v-select>
                        <v-textarea v-else-if="input.type == 'textarea'" :autofocus="idx == 0 ? true : false" :class="'white-bg '+ (input.required ? 'required' : '')" outlined :auto-grow='true' rows='3' hide-details v-model="form_data.inputData[input.value]" :label="input.label" :required="input.required"></v-textarea>
                        <v-menu
                            v-else-if="input.type == 'date'"
                            v-model="input.menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :class="'white-bg '+ (input.required ? 'required' : '')"
                                v-model="form_data.inputData[input.value]"
                                :label="input.label"
                                v-bind="attrs"
                                v-on="on"
                                outlined dense
                                :disabled="getDisabled(input.disabled)"
                                :rules="input.required ? not_null_rule : []"
                            ></v-text-field>
                            </template>
                            <v-date-picker v-model="form_data.inputData[input.value]" @input="input.menu = false" :min="input.min" :max="input.max" scrollable></v-date-picker>
                        </v-menu>
                        <v-text-field v-else :autofocus="idx == 0 ? true : false" :label="input.label" :required="input.required" v-model="form_data.inputData[input.value]" :type="input.type"
                            dense outlined persistent-hint :placeholder="input.label" :class="'white-bg '+ (input.required ? 'required' : '')"
                            :rules="input.type == 'email' ? email_rules : 
                            ( input.type == 'password' ? pass_rules : (input.required ? not_null_rule : [] ))"></v-text-field>
                    </v-flex>
                </v-layout>
            
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="form_data.show = false" flat>Got it!</v-btn>
        </v-card-actions> -->
        <v-card-actions>
          <v-flex class="pa-2 d-flex justify-center">
            <v-btn v-if="form_data.submit" small :color="form_data.type" type="submit" class="right ma-2">Submit</v-btn>
            <v-btn v-else small @click="form_data.show = false" :color="form_data.type" class="right ma-2 elevation-0">Got It</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
</template>

<style>
    .white-bg .v-input__slot{
        background-color: white !important;
    }
</style>

<script>
export default {
    name: 'filter-dialog',
    
    props: {
        form_data: { type: Object, required: false, default: () => ({show: false, text: "", status: 0}) },
        title: {type: String, required: false, default: null}
    },

    data() {
        return {
            ready: false,
            valid: true,

            not_null_rule: [
                v => !!v || 'Input is required',
            ],
            pass_rules: [
                v => !!v || 'Input is required',
                v => (!!v && v.length >= 6) || 'Minimum 6 digit',
            ],
            email_rules: [
                // v => !!v || "Input is required",
                v => !v || (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid')
            ],

            provinces: [],
            city_regencies: [],
            districts: [],
            subdistricts: [],
            medias: [],
        }
    },
    
    methods: {
        getDisabled(rule){
            let result = false;
            if(rule){
                if(rule.negation){
                    if(!this.form_data.inputData[rule.key]) result = true;
                }else{
                    if(this.form_data.inputData[rule.key]) result = true;
                }
            }
            return result;
        },
        validateForm(){
            this.devLog("validating...");
            this.valid = this.$refs.form.validate();
            if ((this.$refs.form).validate()) {
                this.devLog("valid!!!");
                this.form_data.submit();
            }
            else{
                document.getElementsByClassName('v-dialog--active')[0].scrollTop = 0
            }
        },
      close(fx){
        this.form_data.show = false
        fx();
      },
      initData() {
        this.form_data.type = this.form_data.type || "success";
        this.getProvince(false).then( v => this.provinces = v);
        this.getMedia(false).then( v => this.medias = v);
      },

      checkDefault(key){
        switch (key) {
            case "p_id":
                return this.provinces;
            // break;
            case "r_id":
                // if(this.form_data.inputData['p_id']){
                //     this.getCityRegency(this.form_data.inputData['p_id']).then( v => this.city_regencies = v);
                // }
            return this.city_regencies;
            case "d_id":
                // if(this.form_data.inputData['p_id']){
                //     this.getCityRegency(this.form_data.inputData['p_id']).then( v => this.districts = v);
                // }
                return this.districts;
            case "s_id":
                // if(this.form_data.inputData['s_id']){
                //     this.getCityRegency(this.form_data.inputData['s_id']).then( v => this.subdistricts = v);
                // }
                return this.subdistricts;

            case "media_id":
                // if(this.form_data.inputData['s_id']){
                //     this.getCityRegency(this.form_data.inputData['s_id']).then( v => this.subdistricts = v);
                // }
                return this.medias;
                
        
            default:
                return [];
            // break;
        }
      },

    //   allowedDates(val1,val2){
    //     this.devLog(val1)
    //     this.devLog(val2)
    //   }
    allowedDates: val => parseInt(val.split('-')[2], 10) % 2 === 0,
    },
    created () {
      this.devLog("Filter Dialog Component Created...");
      this.initData();
    },
    watch: {
        "form_data.inputData.p_id"(pid){
            // alert(pid);
            this.form_data.inputData.r_id = null;
            if(pid){
                this.getCityRegency(pid).then( v => {
                    this.city_regencies = v
                    this.devLog("CHANGED, now...");
                    this.devLog(v);
                });
            }else{
                this.city_regencies = []
            }
        },

        "form_data.inputData.r_id"(cid){
            this.form_data.inputData.d_id = null;
            if(cid){
                this.getDistrict(cid).then( v => {
                    this.districts = v
                    this.devLog("CHANGED, now...");
                    this.devLog(v);
                });
            }else{
                this.districts = []
            }
        },

        "form_data.inputData.d_id"(did){
            this.form_data.inputData.s_id = null;
            if(did){
                this.getSubdistrict(did).then( v => {
                    this.subdistricts = v
                    this.devLog("CHANGED, now...");
                    this.devLog(v);
                });
            }else{
                this.subdistricts = []
            }
        },
    },
    mounted() {
        this.devLog("filter-dialog component mounted");
    },
}
</script>
