<template>
    <v-breadcrumbs class="pl-0 py-0 my-0"
      :items="map_items"
    ></v-breadcrumbs>
</template>

<script>
export default {
    name: "breadcrumb",
    props: {
        items:{
            type: Array,
            required: true,
        }
    },
    created () {
      this.initData();
    },

    data () {
      return {
        map_items: []
      }
    },

    methods: {
      initData() {
        this.map_items = this.items;
        this.map_items[0].text = "Home"
      }
    },
}
</script>

<style>

</style>