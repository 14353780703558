<template>
    <v-dialog persistent v-model="err_dialog.show" min-width="300" max-width="450" @keydown.esc="$emit('dialog')">
      <v-card class="rounded-lg">
        <!-- <v-toolbar dark :color="err_dialog.type" dense flat>
          <v-toolbar-title class="white--text">{{err_dialog.title}}</v-toolbar-title>
        </v-toolbar> -->
        <v-system-bar
            :color="err_dialog.type"
            dark
            class="pa-6 accent--text text--lighten-1"
          >
            <h3 class="white--text"><b>{{err_dialog.title}}</b></h3>
            <v-spacer></v-spacer>
            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <!-- <v-icon size="20px" class="black--text" v-on="on" @click.stop="()=>{ form_data.show = false }">close</v-icon> -->
                    <v-btn color="primary" icon v-on="on" @click.stop="()=>{ err_dialog.customClose ? err_dialog.customClose() : err_dialog.show = false }"><v-icon>close</v-icon></v-btn>
                </template>
                <span>Tutup</span>
            </v-tooltip>
            
        </v-system-bar>
        <!-- <v-card-text :class="'title '+err_dialog.type+'--text'"><b>{{err_dialog.title}}</b></v-card-text> -->
        <v-card-text class="pt-3">
          <span class="black--text text--secondary">{{err_dialog.subtitle}}</span><br/>
          <span class="subheading">{{err_dialog.message}}</span>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="err_dialog.show = false" flat>Got it!</v-btn>
        </v-card-actions> -->
        <v-card-actions>
          <v-flex class="pa-2 d-flex justify-end">
            <v-btn v-if="err_dialog.customClose" small outlined :color="err_dialog.type" @click="close(err_dialog.customClose)" class="right ma-2">Ok</v-btn>
            <v-btn v-else small outlined @click="err_dialog.show = false" :color="err_dialog.type" class="right ma-2">Got It</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'error-dialog',
    data() {
        return {
            //dialog: {show: null, text: ""},
        }
    },
    props: {
        err_dialog: { type: Object, required: false, default: () => ({show: false, text: "", status: 0}) }
    },
    methods: {
      close(fx){
        this.err_dialog.show = false
        fx();
      },
      initData() {
        this.err_dialog.type = this.err_dialog.type || "error";
      }
    },
    created () {
      //this.devLog("Error Dialog Component Created...");
      this.initData();
    },
    watch: {
      "err_dialog"(val) {
        this.err_dialog = val;
        this.initData();
      }
    },
    mounted() {
        this.devLog("error-dialog component mounted");
    },
}
</script>
