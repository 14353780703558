<template>
    <v-footer padless height="80" bottom>
        <v-col cols="12">
            <p style="text-align: center" class="mb-0 font-weight-light text-caption">
            Copyright © {{currentYear}} by Octopus. All rights reserved
            </p>
        </v-col>
    </v-footer>
</template>

<script>
export default {
    name: "footer-view",
}
</script>

<style>

</style>