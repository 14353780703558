<template>
  <div style="width:100%">
    <!-- <h4 class="text-left primary--text">{{list.title.toUpperCase()}}</h4> -->
    <!-- <v-flex xs12 sm12 md12 text-left align-center v-if="list.title && !list.hide_title">
      <h2 class="primary--text" style="text-transform: uppercase">{{list.title}}</h2>
    </v-flex> -->
    <filter-dialog v-if="list.filter_form && !list.hideFilter" :form_data="list.filter_form"></filter-dialog>
    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12" v-if="list.title && !list.hide_title">
        <h2 class="primary--text text-left text-uppercase">{{list.title}}</h2>
      </v-col>
    </v-row>

      <v-row>
        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectDate">
          <v-menu
            v-model="filter_date"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="f_date"
                label="Date Filter"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="f_date"
              @input="filter_date = false"
              color="primary"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectSpecialist">
          <v-autocomplete
          :items="list.specialist"
          v-model="f_specialist"
          label="Specialist Filter"
          item-value="name" 
          item-text="name"
          dense
          outlined
          hide-details
          multiple
          small-chips
        ></v-autocomplete>
        </v-col>
        
        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectService">
          <v-autocomplete
            :items="service_list"
            v-model="f_services"
            label="Service"
            item-value="id" 
            :item-text="list.serviceSimpleName ? 'name' : getServiceText"
            dense
            outlined
            clearable
            hide-details
            :multiple = "list.multiple_f_service || false"
            :small-chips  = "list.multiple_f_service || false"
            :allow-overflow="false"
          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectExpert">
          <v-autocomplete
            :items="therapist_list"
            v-model="f_experts"
            label="Expert"
            item-value="id" 
            item-text="name"
            dense
            :disabled="this.list.expert_id ? true : false"
            outlined
            hide-details
            clearable
            :multiple = "list.multiple_f_expert || false"
            :small-chips  = "list.multiple_f_expert || false"
          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectTherapist">
          <v-autocomplete
            :items="therapist_list"
            v-model="f_therapists"
            label="Expert"
            item-value="id" 
            :item-text="getTherapistText"
            dense
            outlined
            hide-details
            :multiple = "list.multiple_f_therapist || false"
            :small-chips  = "list.multiple_f_therapist || false"
          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectGender">
          <v-autocomplete
            :items="list.gender"
            v-model="gender"
            label="Gender Filter"
            item-value="value" 
            item-text="name"
            dense
            outlined
            hide-details
          ></v-autocomplete>
        </v-col>

        <v-col cols="6" sm="4" md="3" class="py-0 pr-0" v-if="list.canSelectStatus">
          <v-autocomplete
            :items="list.f_status"
            v-model="f_statuses"
            :label="list.t_status"
            item-value="value" 
            item-text="text"
            dense
            outlined
            hide-details
            :multiple = "list.multiple_f_status || false"
            :small-chips  = "list.multiple_f_status || false"
          ></v-autocomplete>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="8" sm="4" md="3" class="py-2 pr-0" v-if="!list.hideSearch">
          <v-form @submit.prevent="checkSearch()">
              <v-text-field outlined dense :placeholder="'Search: (Type at least '+ (list.min_key != null ? list.min_key : '2') +' character then hit `Enter`)'" width="50%" class="pt-0" v-model="list.search" append-icon="search" label="Search" single-line hide-details></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="4" sm="3" md="2" class="pa-0 ma-0" style="padding-top: 10px !important;">
          <v-btn class="py-2" color="secondary darken-2" @click="() => { list.filter_form.show = true}" outlined v-if="list.filter_form && !list.hideFilter">
              <v-icon
              small
                  right
                  dark
                  size="16"
              >
                  filter_alt
              </v-icon>&nbsp;
              Filter&nbsp;&nbsp;
          </v-btn>
        </v-col>

        <v-col cols="12" sm="5" md="7" class="text-right py-2 pr-0">
          <v-btn color="info" style="margin-right: 10px;" outlined dark @click="list.bulk(list)" v-if="(!!list.selected && list.selected.length > 0) || list.select_btn_presist">{{list.bulk_action || 'Print'}} Selected</v-btn> 
          <v-btn color="info" style="margin-right: 10px;" outlined dark @click="list.sync()" v-if="list.sync">Sync Doc</v-btn> 
          <v-btn color="info" style="margin-right: 10px;" outlined dark @click="list.calendar.action()" v-if="list.calendar"><v-icon left>calendar_month</v-icon>{{ list.calendar.name }}</v-btn>
          <v-btn color="info" style="margin-right: 10px;" outlined dark @click=" list.export.action ? list.export.action() : list.export()" v-if="list.export"><v-icon left>file_download</v-icon> {{ list.export.name ? list.export.name : 'Export Data'}}</v-btn>
          <v-btn color="info" style="margin-right: 10px;" outlined dark @click="list.custom.action()" v-if="list.custom && !list.hideCustom"><v-icon left>{{ list.custom.icon ? list.custom.icon : 'dialpad' }}</v-icon>{{ list.custom.name ? list.custom.name : 'Custom' }}</v-btn>
          <v-btn color="primary" style="margin-right: 10px;" outlined dark @click="list.add.action()" v-if="list.add && !list.hideAdd"><v-icon left>add</v-icon>{{ list.add.name ? list.add.name : 'Add Data' }}</v-btn>
        </v-col>
      </v-row>
    

    <v-row>
      <v-col cols="12" xs="12" sm="12" md="12"> 
        <v-data-table
          :page="page"
          :pageCount="last_page"
          :headers="list.headers"
          :items="list.datas"
          :options.sync="list.options"
          :server-items-length="total"
          :loading="list.loading"
          class="elevation-1"
          :footer-props="{
              itemsPerPageOptions:list.options.rowsPerPage || [5, 10, 25, 50]
          }"
          disable-sort
          :item-key="list.select_key"
          :show-select="list.bulk? true: false"
          v-model="list.selected"
          @toggle-select-all="selectAllToggle"
          :single-select="list.singleSelect? true : false"
        >
          <template v-slot:item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox v-if="!item.validity"
              :value="isSelected"
              @input="select($event)"
            ></v-simple-checkbox>
            <v-icon v-else size="22" color="success">verified</v-icon>
          </template>

          <template v-slot:item.index="{ index }">
            {{(index+1) + ((list.options.page-1) * list.options.itemsPerPage)}}.
          </template>

          <template v-slot:item.url="{ header, item }">
            <a target="_blank" :href="item[header.field]">{{item[header.field]}}</a>
          </template>

          <template v-slot:item.telephone="{ item }">
            <span v-html="formatPhone(item.country_code, item.phone) "></span>
          </template>

          <template v-slot:item.money="{ header, item }">
            <span v-html="formatMoney(item[header.field])"></span>
          </template>

          <template v-slot:item.switch="{ header, item }">
            <span v-if="header.field == 'status'">
              <v-switch dense readonly v-model="switchStatus" :value="item[header.field]"></v-switch>
            </span>
            <span v-else></span>
          </template>

          <template v-slot:item.boolean="{ header, item }">
            <span v-html="(header.casting[item[header.field]])"></span>
          </template>

          <template v-slot:item.image="{ header, item }">
            <v-avatar size="40" :color="header.parent || ( item[header.field] != null) ? ( item['color'] ? item['color'] :'pink lighten-5'): 'primary darken-2'">
              <img v-if="header.parent && item[header.parent] && item[header.parent][header.field]" :src="item[header.parent][header.field]"/>
              <span v-else-if="header.parent && item[header.parent]" class="white--text text-h6">{{ $root.getNameAvatar((item[header.parent].name || item[header.parent].paslon_name)) }}</span>
              <span v-else-if="header.parent && !item[header.parent]" class="white--text text-h6">&#183;</span>
              <img v-else-if="item[header.field] != null" :src="item[header.field]" />
              <span v-else class="white--text text-h6">{{ $root.getNameAvatar((item.name || item.paslon_name)) }}</span>
            </v-avatar>
          </template>

          <template v-slot:item.base64image="{ header, item }">
            <v-img contain width="40" height="auto" v-if="header.parent && item[header.parent] && item[header.parent][header.field]" :src="'data:image/jpg;base64, '+item[header.parent][header.field]"></v-img>
            <v-img contain width="40" height="auto" v-else-if="item[header.field] != null" :src="'data:image/jpg;base64, '+item[header.field]" ></v-img>
            <span v-else class="white--text text-h6">No Foto</span>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-layout row wrap>
              <v-flex xs12 class="text-xs-center align-center">
                  <template v-for="(btn,idx) in list.actions">
                    <v-tooltip bottom v-if="btn.fx && checkRule(btn.rules, item)" :key="'action_btn-'+idx">
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon small :class=" 'ma-1 ' + (btn.color || 'primary') + '--text border-debug' " v-bind="attrs" v-on="on" @click.stop="btn.fx(item)">{{btn.icon}}</v-icon>
                          </template>
                          <span>{{btn.label}}</span>
                      </v-tooltip>
                  </template>
              </v-flex>
            </v-layout>
          </template>

           <template v-slot:item.parent="{ header, item }">
            <span v-if="item[header.parent]">
              <span v-if="header.field == 'name'" 
                v-html="camelize((item[header.parent][header.field] || '-'))">
              </span>
              <span v-else>
                {{item[header.parent][header.field] || '-'}}
              </span>
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.grand_parent="{ header, item }">
            <span v-if="item[header.parent][header.grand_parent]">{{item[header.parent][header.grand_parent][header.field] || '-'}}</span>
            <span v-else>-</span>
          </template>

          <template v-slot:item.list="{ header, item }">
            <span v-if="item[header.parent]">
              <ul class="mt-1 mb-1">
                <li v-for="(value, index) in item[header.parent]" :key="value.name+index">
                  {{ value.name }}
                </li>
              </ul>
            </span>
            <span v-else>-</span>
          </template>
        </v-data-table>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import FilterDialog from "./filter-dialog.vue";
export default {
  name: "data-list-paginate",
  components: {
    FilterDialog
  },
  data() {
    return {
      retry: 0,
      interval: 500,
      max_try: 6,
      page: 1,
      total: 0,
      last_page: 0,
      is_loading: false,
      tamp_tab: null,

      f_specialist: [],
      gender: "All",
      f_services: [],
      service_list: [],
      f_therapists: [],
      therapist_list: [],
      f_statuses: [],
      f_experts: [],
      switchStatus: ['Active'],
      disabledCount: 0,
      filter_date: false,
      f_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      f_status: [],
    };
  },


  created(){
      if(this.list.bulk && this.list.bulk_key){
        this.list.datas.map(item => {
          if (!item[this.list.bulk_key]) this.disabledCount += 1
        })
      }
      
      if(this.$root.is_login){
        this.initialize();
      }
  },

  computed: {
    // getSwtichStatusValue (status) {
    //   return status == 'Active' ? true : false
    // },
  },

  props: {
      api: {
          type: String,
          required: true,
      },
      list: {
          type: Object,
          required: true,
      },
      filter_query: {
          type: String,
          default: "",
      },
      tab: {
        type: String,
        default: null,
      },
      selected_tab: {
        type: String,
        default: null,
      },
  },
  //this one will populate new data set when user changes current page. 
  watch: {
      "filter_query"(){
        this.devLog("readDataFromAPI from: watcher filter_query");
        this.readDataFromAPI();
      },
      "list.options": {
          handler() {
            if(this.list.canSelectTab && !this.list.selectedTab){
              return false;
            }
            if(this.tab && this.selected_tab != this.tab){
              return false;
            }
            this.devLog(this.tab);
            this.devLog(this.selected_tab);
            this.devLog("readDataFromAPI from: watcher list.options");
            this.readDataFromAPI();
          },
          deep: true,
      },
      
      "list.datas": {
          handler() {
              if(this.list.bulk && this.list.bulk_key){
                this.disabledCount = 0;
                this.list.datas.map(item => {
                  if (item[this.list.bulk_key]) this.disabledCount += 1
                })
              }
          },
          deep: true,
      },
      "list.search"(val){
        if(val.length == 0){
          this.devLog("readDataFromAPI from: watcher list.search");
          this.readDataFromAPI();
        }
      },
      "list.selectedTab"(val){
        if(val){
          this.devLog("readDataFromAPI from: watcher list.selectedTab");
          this.readDataFromAPI();
        }
      },
      "f_specialist"(val){
        if(val){
          this.readDataFromAPI();
        }
      },
      "gender"(val){
        if(val){
          this.readDataFromAPI();
        }
      },
      "f_services"(val){
        if(val || !val){
          this.readDataFromAPI();
        }
      },
      "f_therapists"(val){
        if(val){
          this.readDataFromAPI();
        }
      },
      "f_statuses"(val){
        if(val){
          this.readDataFromAPI();
        }
      },
      "f_experts"(val){
        if(val || !val){
          this.readDataFromAPI();
        }
      },
      "f_date"(val){
        this.devLog(val)
        if(val){
          this.readDataFromAPI();
        }
      },
      "list.selected_status"(val){
         this.devLog(val)
          if(val){
            this.devLog("readDataFromAPI from: watcher list.selected_status");
            this.readDataFromAPI();
          }
      }

  },
  methods: {
    selectAllToggle(props) {
      this.devLog(props);
      this.devLog(this.list.selected.length)
      this.devLog(this.list.datas.length)
      this.devLog(this.disabledCount)
      if(this.list.selected.length != this.list.datas.length - this.disabledCount) {
        props.items.forEach( v => {
          if(!v[this.list.bulk_key]){
            this.list.selected.push(v);
          }
        });
      }else{
        // this.$nextTick(()=>{
          this.list.selected = [];
        // })
      }
      
      // this.list.selected = this.list.selected.filter((v) => {
      //   return !v.validity
      // })
      this.devLog(this.list.selected);
    },
    initialize(){
      if(this.list.canSelectService){
        this.getServiceFilter();
      }
      if(this.list.canSelectTherapist || this.list.canSelectExpert){
        this.getTherapistFilter();
      }
      // this.devLog("readDataFromAPI from: initialize");
      // this.readDataFromAPI();
    },

    getSwtichStatusValue (status) {
      let x = status == 'Active' ? true : false
      this.devLog("status == "+ status)
      this.devLog(x)
      return x;
    },
    
    getTherapistText(item){
      return item.name
    },
    checkSearch(){
      if(this.list.search.length >= (this.list.min_key || 2) ){
        this.list.options.page = 1;
        this.readDataFromAPI();
      }else{
        this.alert("Warning", "Please type atleast "+ (this.list.min_key || 2)+" keyword to search!", {color: "warning", width: 500});
      }
    },
    //Reading data from API method. 
    readDataFromAPI() {
        if(!this.$root.is_login){
          // if(this.retry <= this.max_try){
          //   this.retry++;
          //   setTimeout(this.readDataFromAPI(), this.interval);
          // }else{
            return false;
          // }
        }
        // if(this.list.loading){
        //   return false;
        // }
        this.list.loading = true;
        let data = {};
        const { page, itemsPerPage } = this.list.options;
        // let pageNumber = page - 1;
        let url = this.api+"per_page=" + itemsPerPage + "&page=" + page

        if(this.list.canSelectDate && this.f_date){
          url = url + "&date="+this.f_date
        }

        if(this.list.canSelectSpecialist && (this.f_specialist.length || this.f_specialist > 0)){
          data.product_services = this.f_specialist
        }

        if(this.gender && this.list.canSelectGender){
          url = url + "&gender="+this.gender;
        }
        
        if(this.list.canSelectService && this.f_services.length){
          if(this.list.multiple_f_service){
            if(this.f_services.length > 0){
              data.product_service_ids = this.f_services
            }
            
          }else{
            if(this.f_services.length > 0){
              url = url + "&product_service_id="+this.f_services;
            }
          }
        }

        if(this.list.canSelectTherapist && (this.f_therapists.length || this.f_therapists > 0)){
          if(this.list.multiple_f_therapist){
            data.user_ids = this.f_therapists
          }else{
            url = url + "&user_id="+this.f_therapists;
          }
        }

        if(this.list.canSelectExpert && this.f_experts > 0){
          if(this.list.multiple_f_expert){
            data.expert_ids = this.f_experts
          }else{
            url = url + "&expert_id="+this.f_experts;
          }
        }

        if(this.list.canSelectStatus && this.f_statuses.length){
          if(this.list.multiple_f_status){
            data.statuses = this.f_statuses
          }else{
            url = url + "&status="+this.f_statuses;
          }
        }
        
        if(this.list.canSelectTab && this.list.selectedTab){
          url = url + "&tab="+this.list.selectedTab;
        }

        if(this.list.selected_status && this.list.canSelectTab){
          url = url + "&tab="+this.list.selected_status;
        }

        if(this.list.search.length >= (this.list.min_key || 2) ){
          url = url + "&keyword="+this.list.search;
        }

        if(this.filter_query.length > 0){
          url = url + this.filter_query
        }
        
        this.$axios.get( url ,{ params: data, headers: { 'Authorization': localStorage.token } } )
        .then((response) => {
            this.devLog(url);
            this.devLog(response);
            //Then injecting the result to datatable parameters.
            this.devLog(response.data.data);
            this.list.loading = false;
            this.list.datas = response.data.data;
            this.total = response.data.total;
            this.last_page = response.data.last_page;
        }).catch((err)=>{
            this.list.loading = false;
            if(err.response){
                this.showErr(err.response, "Failed");
            }
            else{
                this.showErr({status:"Code Error", statusText: err});
            }

            this.devLog(url);
            
        }).finally(() => {
            this.list.loading = false;
            this.is_loading = false;
        });
    },
    //Check Actions Rule
    checkRule(rules, item){
        let check_result = true;
        if(rules && rules.length > 0){
            rules.forEach(rule => {
                if(rule.negation){
                    //If rule negation true, than check result will false if both same
                    if(item[rule.key] == rule.value) check_result = false;
                }else{
                    //If rule negation false, than check result will false if both not same
                    if(item[rule.key] != rule.value) check_result = false;
                }
            });
        }
        return check_result;
    },

    //Get Service Items from API method. 
    getServiceFilter() {
        let url = this.apiBooking+'/services/list'
        
        this.$axios.get( url ,{ headers: { 'Authorization': localStorage.token } } )
        .then((response) => {
          this.devLog("Response SERVICES");
            this.devLog(response);
            this.service_list = response.data || [];
        }).catch((err)=>{
            if(err.response){
                this.showErr(err.response, "Failed");
            }
            else{
                this.showErr({status:"Code Error", statusText: err});
            }
            
        });
    },

    //Get Therapist Items from API method. 
    getTherapistFilter() {
        let url = this.apiBooking+'/therapist/list'
        
        this.$axios.get( url ,{ headers: { 'Authorization': localStorage.token } } )
        .then((response) => {
            this.devLog("Response THERAPIST");
            this.devLog(response);
            this.therapist_list = response.data || [];
        }).catch((err)=>{
            if(err.response){
                this.showErr(err.response, "Failed");
            }
            else{
                this.showErr({status:"Code Error", statusText: err});
            }
            
        });
    },
  },
    //this will trigger in the onReady State
    mounted() {
        // this.devLog("readDataFromAPI from: mounted");
        // this.readDataFromAPI();
    },
};
</script>