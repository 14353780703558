<template>
    <v-app >
      <confirm ref="confirm"></confirm>
      <confirm ref="dialog"></confirm>
      <error-dialog v-model="this.$root.err_dialog" :err_dialog="this.$root.err_dialog"></error-dialog>
      <loading-dialog ref="loading" :dialog="$root.loading_dialog"></loading-dialog>
      <alert-dialog ref="alert"></alert-dialog>

      <v-snackbar
            v-model="$root.snackbar.notification"
            right
            top
            :color="$root.snackbar.type"
            :timeout="$root.snackbar.timeout"
            small
        >
          <i><b>{{ $root.snackbar.notificationText }}</b></i>

          <v-btn
              v-if="$root.snackbar.hasButton"
              text depressed small
              :color="$root.snackbar.buttonColor"
              @click="$root.snackbar.onClick"
              class="ml-4"
          >
              {{ $root.snackbar.buttonText }}
          </v-btn>
      </v-snackbar>
      
      <navigation class="hide-print" v-if="this.$root.is_login && !this.$root.isWebview" :navigation="this.$root.navigation" />
      <v-main>
          <router-view ></router-view>
      </v-main>
      <!-- <v-main v-else class="loading_cover"></v-main> -->
      <footer-view class="hide-print" v-if="this.$root.is_login && !this.$root.isWebview" />
    </v-app>
</template>

<style>
@media print {
  .hide-print {
    display: none;
    visibility: hidden;
  }
  
  .input-field input,
  table input {
    border-bottom: none;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#drawer-header{
    background-repeat: no-repeat;
    background-size: 125px;
    background-position-x: center;
    background-position-y: center;
    background-color: black;
    height : 175px;
}

.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 0px !important;
}

.v-toolbar__content{
    padding: 4px 50px !important;
}

/* .v-toolbar__title{
    font-size: calc(10px + 1vw) !important;
} */
</style>

<style lang="scss">
  @import '@/scss/styles.scss';
</style>

<script>

export default {
  name: 'App',

  components: {

  },

  created () {
    this.devLog("App created ...");
  },

  data() {
    return {
 
    }
  },

  watch:{

  },

  methods: {

  },

  mounted(){
    this.devLog("App mounted ...");
    this.devLog(this.$root.userLogin)
  },
};
</script>
