import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

var baseUrl = "";
var baseUrlCore = "";
var nodeENV = "";

if ( process.env.NODE_ENV == 'production' ) {
  //BE SERVER
  baseUrl = process.env.VUE_APP_API_URL;
  //FE SERVER
  baseUrlCore = process.env.VUE_APP_API_URL;
  //ENV NAME
  nodeENV = 'production';
} else {
  //BE SERVER
  baseUrl = process.env.VUE_APP_API_URL;
  //FE SERVER
  baseUrlCore = process.env.VUE_APP_API_URL;
  //ENV NAME
  nodeENV = 'development';
}
//be api endpoint
const core = "/api/v1";
//const api endpoint
const apiCore = baseUrl+core;
const api = baseUrl+core;
//const app endpoint
const feCore = baseUrlCore;

Vue.mixin({
  data() {
      return {
          //API Endpoint
          API: api,
          apiCore: apiCore,
          //APP Endpoint
          feCore: feCore,
          nodeENV: nodeENV,
      }
  },
});

const routesArray = [];
routesArray.push({path: '/', name: 'redirect', redirect: '/login', meta: {requiresVisitor: true}})
routesArray.push({path: '/login', name: 'login', component: () => import(/* webpackChunkName: "LoginRegister" */ '../views/login'), meta: {requiresVisitor: true}})
routesArray.push({path: '/register', name: 'register', component: () => import(/* webpackChunkName: "LoginRegister" */ '../views/register'), meta: {requiresVisitor: true}})
routesArray.push({path: '/forget-password', name: 'forget-password', component: () => import(/* webpackChunkName: "LoginRegister" */ '../views/forget-password'), meta: {requiresVisitor: true}})
routesArray.push({path: '/reset-password', name: 'reset-password', component: () => import(/* webpackChunkName: "LoginRegister" */ '../views/reset-password'), meta: {requiresVisitor: true}})

routesArray.push({path: '/dashboard', name: 'dashboard', component: () => import(/* webpackChunkName: "Dashboard" */ '../views/dashboard/dashboard'), meta: {requiresAuth: true}})

// User Management
routesArray.push({path: '/user', name: 'user-list', component: () => import(/* webpackChunkName: "ListUser" */ '../views/user/user-list'), props: { api: api+"/user/paginate?", apiCUD: api+"/user"}, meta: {requiresAuth: true}})
routesArray.push({path: '/user/add', name: 'add-user', component: () => import(/* webpackChunkName: "CRUUser" */ '../views/user/cru-users'), props: {apiCUD: api+"/user"}, meta: {requiresAuth: true}})
routesArray.push({path: '/user/:id', name: 'detail-user', component: () => import(/* webpackChunkName: "CRUUser" */ '../views/user/cru-users'), props: {apiCUD: api+"/user"}, meta: {requiresAuth: true}})
routesArray.push({path: '/user/edit/:id', name: 'edit-user', component: () => import(/* webpackChunkName: "CRUUser" */ '../views/user/cru-users'), props: {apiCUD: api+"/user"}, meta: {requiresAuth: true}})

// routesArray.push({path: '/profile', name: 'profile', component: Profile, props: { apiUser: api+"/user",apiCustomer: api+"/customer", editable: false}, meta: {requiresAuth: true}})
routesArray.push({path: '/profile', name: 'edit-profile', component: () => import(/* webpackChunkName: "ListUser" */ '../views/user/profile'), props: { apiUser: api+"/user", editable: true}, meta: {requiresAuth: true}})

// Role Management
routesArray.push({path: '/role', name: 'role-list', component: () => import(/* webpackChunkName: "ListRole" */ '../views/role/role-list'), props: { api: api+"/role/paginate?", apiCUD: api+"/role"}, meta: {requiresAuth: true}})
routesArray.push({path: '/role/add', name: 'add-role', component: () => import(/* webpackChunkName: "CruRole" */ '../views/role/cru-role'), props: {apiCUD: api+"/role", editable: true}, meta: {requiresAuth: true}})
routesArray.push({path: '/role/:id', name: 'detail-role', component: () => import(/* webpackChunkName: "CruRole" */ '../views/role/cru-role'), props: {apiCUD: api+"/role", editable: false}, meta: {requiresAuth: true}})
routesArray.push({path: '/role/edit/:id', name: 'edit-role', component: () => import(/* webpackChunkName: "CruRole" */ '../views/role/cru-role'), props: {apiCUD: api+"/role", editable: true}, meta: {requiresAuth: true}})

//DPT
routesArray.push({path: '/dpt', name: 'dpt', component: () => import(/* webpackChunkName: "Dpt" */ '../views/dpt/list-dpt'), props: { api: api+"/dpt/paginate?", apiCUD: api+"/dpt"}, meta: {requiresAuth: true}})
routesArray.push({path: '/dpt/detail', name: 'detail-dpt', component: () => import(/* webpackChunkName: "ViewDpt" */ '../views/dpt/detail-dpt'), props: true, meta: {requiresAuth: true}})

//C1
routesArray.push({path: '/c1', name: 'c1', component: () => import(/* webpackChunkName: "C1" */ '../views/c1/c1-list'), props: { api: api+"/c1/paginate?type=", apiCUD: api+"/c1"}, meta: {requiresAuth: true}})
routesArray.push({path: '/c1/detail', name: 'detail-c1', component: () => import(/* webpackChunkName: "ViewC1" */ '../views/c1/detail-c1'), props: true, meta: {requiresAuth: true}})


// Partai
routesArray.push({path: '/partai', name: 'partai', component: () => import(/* webpackChunkName: "Partai" */ '../views/master-data/partai/list-partai'), props: { api: api+"/partai/paginate?", apiCUD: api+"/partai"}, meta: {requiresAuth: true}})
routesArray.push({path: '/partai/edit/:id', name: 'edit-partai', component: () => import(/* webpackChunkName: "CUPartai" */ '../views/master-data/partai/partai-cu-dialog'), props: { api: api+"/partai", apiCUD: api+"/partai"}, meta: {requiresAuth: true}})

// Paslon
routesArray.push({path: '/paslon', name: 'paslon', component: () => import(/* webpackChunkName: "Paslon" */ '../views/master-data/paslon/list-paslon'), props: { api: api+"/paslon/paginate?", apiCUD: api+"/paslon"}, meta: {requiresAuth: true}})
routesArray.push({path: '/paslon/edit/:id', name: 'edit-paslon', component: () => import(/* webpackChunkName: "CUPaslon" */ '../views/master-data/paslon/paslon-cu-dialog'), props: { api: api+"/paslon", apiCUD: api+"/paslon"}, meta: {requiresAuth: true}})

// Master Data
routesArray.push({path: '/dpt-2019', name: 'dpt-2019', component: () => import(/* webpackChunkName: "Dpt2019" */ '../views/master-data/dpt-2019'), props: { api: api+"/dpt-2019/paginate?", apiCUD: api+"/dpt-2019"}, meta: {requiresAuth: true}})
routesArray.push({path: '/province', name: 'province', component: () => import(/* webpackChunkName: "Province" */ '../views/master-data/wilayah/province'), props: { api: api+"/provinsi/paginate?", apiCUD: api+"/provinsi"}, meta: {requiresAuth: true}})
routesArray.push({path: '/regency', name: 'regency', component: () => import(/* webpackChunkName: "Regency" */ '../views/master-data/wilayah/regency'), props: { api: api+"/kota_kabupaten/paginate?", apiCUD: api+"/kota_kabupaten"}, meta: {requiresAuth: true}})
routesArray.push({path: '/district', name: 'district', component: () => import(/* webpackChunkName: "District" */ '../views/master-data/wilayah/district'), props: { api: api+"/kecamatan/paginate?", apiCUD: api+"/kecamatan"}, meta: {requiresAuth: true}})
routesArray.push({path: '/subdistrict', name: 'subdistrict', component: () => import(/* webpackChunkName: "Subdistrict" */ '../views/master-data/wilayah/subdistrict'), props: { api: api+"/desa/paginate?", apiCUD: api+"/desa"}, meta: {requiresAuth: true}})
// routesArray.push({path: '/regency', name: 'regency', component: () => import(/* webpackChunkName: "Regency" */ '../views/role/role-list'), props: { api: api+"/role/paginate?", apiCUD: api+"/role"}, meta: {requiresAuth: true}})
// routesArray.push({path: '/district', name: 'district', component: () => import(/* webpackChunkName: "District" */ '../views/role/role-list'), props: { api: api+"/role/paginate?", apiCUD: api+"/role"}, meta: {requiresAuth: true}})
// routesArray.push({path: '/subdistrict', name: 'subdistrict', component: () => import(/* webpackChunkName: "Subdistrict" */ '../views/role/role-list'), props: { api: api+"/role/paginate?", apiCUD: api+"/role"}, meta: {requiresAuth: true}})

//Alat Peraga
routesArray.push({path: '/props', name: 'props', component: () => import(/* webpackChunkName: "AlatPeraga" */ '../views/master-data/peraga/list-alat-peraga'), props: { api: api+"/alat-peraga/paginate?", apiCUD: api+"/alat-peraga"}, meta: {requiresAuth: true}})
routesArray.push({path: '/props-form', name: 'props-form', component: () => import(/* webpackChunkName: "PemasanganAlatPeraga" */ '../views/peraga/list-pemasangan-alat-peraga'), props: { api: api+"/pemasangan-alat-peraga/paginate?", apiCUD: api+"/pemasangan-alat-peraga"}, meta: {requiresAuth: true}})
routesArray.push({path: '/props-form/detail', name: 'detail-form-props', component: () => import(/* webpackChunkName: "ViewFormProps" */ '../views/peraga/detail-pemasangan-peraga'), props: true, meta: {requiresAuth: true}})

//Social Media Monitoring
routesArray.push({path: '/social-media', name: 'social-media', component: () => import(/* webpackChunkName: "SocialMedia" */ '../views/socmed/list-socmed'), props: { api: api+"/socmed-monitor/paginate?", apiCUD: api+"/socmed-monitor"}, meta: {requiresAuth: true}})
routesArray.push({path: '/news', name: 'news', component: () => import(/* webpackChunkName: "News" */ '../views/news/list-news'), props: { api: api+"/news/paginate?", apiCUD: api+"/news"}, meta: {requiresAuth: true}})

//Media
routesArray.push({path: '/media', name: 'media', component: () => import(/* webpackChunkName: "Media" */ '../views/master-data/media/list-media'), props: { api: api+"/media/paginate?", apiCUD: api+"/media"}, meta: {requiresAuth: true}})

//POOLING
routesArray.push({path: '/polling', name: 'polling', component: () => import(/* webpackChunkName: "Polling" */ '../views/master-data/polling/list-polling'), props: { api: api+"/polling/paginate?", apiCUD: api+"/polling"}, meta: {requiresAuth: true}})
routesArray.push({path: '/wv/AIzaSyDkyrmhYBt6vT_aCxLv_d47iZoIoLwc_WA/polling', name: 'polling', component: () => import(/* webpackChunkName: "PollingWv" */ '../views/master-data/polling/list-polling'), props: { api: api+"/polling/paginate?", apiCUD: api+"/polling"}, meta: {}})
routesArray.push({path: '/polling/print/:id', name: 'polling-print', component: () => import(/* webpackChunkName: "PollingPrint" */ '../views/master-data/polling/polling-print'), props: { api: api+"/polling/:id", apiCUD: api+"/polling"}, meta: {}})
routesArray.push({path: '/polling/graph/:id', name: 'polling-graph', component: () => import(/* webpackChunkName: "PollingGraph" */ '../views/master-data/polling/polling-graph'), props: { api: api+"/polling/:id/results", apiCUD: api+"/polling"}, meta: {}})
routesArray.push({path: '/wv/AIzaSyDkyrmhYBt6vT_aCxLv_d47iZoIoLwc_WA/polling/graph/:id', name: 'polling-graph', component: () => import(/* webpackChunkName: "PollingGraphWv" */ '../views/master-data/polling/polling-graph'), props: { api: api+"/polling/:id/results", apiCUD: api+"/polling"}, meta: {}})
routesArray.push({path: '/quest/:uuid', name: 'quest', component: () => import(/* webpackChunkName: "Quest" */ '../views/master-data/polling/questionaire'),props: { api: api}, meta: {}})
routesArray.push({path: '/wv/AIzaSyDkyrmhYBt6vT_aCxLv_d47iZoIoLwc_WA/quest/:uuid', name: 'quest', component: () => import(/* webpackChunkName: "QuestVw" */ '../views/master-data/polling/questionaire'),props: { api: api}, meta: {}})
routesArray.push({path: '/view/:slug', name: 'dynamic-webview', component: () => import(/* webpackChunkName: "DynamicWebView" */ '../views/dynamic-webview'), meta: {}})

routesArray.push({path: '*', name: 'not-found', component: () => import(/* webpackChunkName: "NotFound" */ '../views/not-found')})

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routesArray
})

export default router

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    
    //Check Is Web View flag from url
    Vue.nextTick(()=>{
      if(to.query && to.query.iwv){
        if(to.query.iwv == 1){
          router.app.$root.changeIsWebview(true)
        }else{
          router.app.$root.changeIsWebview(false)
        }
      }
    })

    let userLogin = localStorage.getItem('userLogin') || false;
    let token = localStorage.getItem('token') || false;

    if (!token || !userLogin) {
      next({
        path: '/login',
      })
    }

    next()

  } else if(to.matched.some(record => record.meta.requiresVisitor)){
      let userLogin = localStorage.getItem('userLogin') || false;
      let token = localStorage.getItem('token') || false;
      
      // Wajib nextTick, biar bisa baca router.app
      Vue.nextTick(()=>{
        if(userLogin && token && router.app.$root.checkLogin()){
          next({ path: '/dashboard'})
        }else{
          next();
        }
      });
      
  } else{
    next()
  }
})
