<template>
  <div>
      <v-card flat tile top>
        <v-toolbar
          color="primary"
        >
         <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-app-bar-nav-icon class="white--text" v-on="on" @click.stop="()=>{navigation.drawer = !navigation.drawer; $root.updateDrawer();}"></v-app-bar-nav-icon>
            </template>
            <span>Menu</span>
         </v-tooltip>
          
          <v-spacer></v-spacer>

          <v-toolbar-title class="white--text pr-3 font-weight-bold">{{ $root.userLogin.name }}</v-toolbar-title>

          <!-- <router-link class="text-decoration-none" to="/view/profile">
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-avatar v-on="on" color="grey">
                      <img
                      v-if="$root.userLogin.avatar != null"
                        :src="$root.userLogin.avatar != null ? $root.userLogin.avatar : ''"
                      >
                      <span v-else class="white--text text-h5">{{ getNameAvatar($root.userLogin.name) }}</span>
                    </v-avatar>
                </template>
                  <span>Profile</span>
              </v-tooltip>
          </router-link> -->

          <span class="text-xs-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              :nudge-bottom="12"
              offset-y
              bottom
            >
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-avatar v-bind="attrs" v-on="{ ...tooltip, ...menu }" color="primary lighten-2">
                      <img
                      v-if="$root.userLogin.avatar != null"
                        :src="$root.userLogin.avatar != null ? $root.userLogin.avatar : ''"
                      >
                      <span v-else class="white--text text-h5">{{ getNameAvatar($root.userLogin.name) }}</span>
                    </v-avatar>
                  </template>
                  <span>Profile</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-text class="accent--text text--lighten-0" style="background-color: var(--v-primary-darken4);">
                  <v-avatar color="primary lighten-2" width="100" height="100">
                    <img
                      v-if="$root.userLogin.avatar != null"
                      :src="$root.userLogin.avatar != null ? $root.userLogin.avatar : 'https://cdn.vuetifyjs.com/images/john.jpg'"
                    >
                    <span v-else class="white--text text-h5">{{ getNameAvatar($root.userLogin.name) }}</span>
                  </v-avatar>
                  
                  <div class="pt-2">
                    <b>{{user.name}}</b><br>
                    <b>{{user.role.name}}</b>
                  </div>
                  

                </v-card-text>
                
                <v-divider></v-divider>

                <v-card-actions>

                  <!-- <v-btn
                    color="primary"
                    @click="menu = false"
                    outlined
                    small
                  >
                    <v-icon size="16">person</v-icon>&nbsp;
                    Profile
                  </v-btn> -->

                  <router-link class="text-decoration-none" to="/profile">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <!-- <v-avatar v-on="on" color="grey">
                              <img
                              v-if="$root.userLogin.avatar != null"
                                :src="$root.userLogin.avatar != null ? $root.userLogin.avatar : ''"
                              >
                              <span v-else class="white--text text-h5">{{ getNameAvatar($root.userLogin.name) }}</span>
                            </v-avatar> -->

                            <v-btn v-on="on"
                            color="primary"
                            @click="menu = false"
                            outlined
                            small
                          >
                            <v-icon size="16">person</v-icon>&nbsp;Profile
                          </v-btn>
                        </template>
                          <span>Profile</span>
                      </v-tooltip>
                  </router-link>

                  <v-spacer></v-spacer>

                  <!-- <v-btn
                    icon
                    rounded
                    @click="menu = false"
                  >
                    <v-icon>lock</v-icon>
                  </v-btn> -->
                  <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      color="error"
                      icon
                      v-on="{ ...tooltip, ...menu }"
                      @click="$root.logout()"
                    >
                      <v-icon>exit_to_app</v-icon>
                    </v-btn>
                  </template>
                  <span>Log Out</span>
                </v-tooltip>
                
                </v-card-actions>
              </v-card>
            </v-menu>
          </span>
        </v-toolbar>
      </v-card>

      <v-navigation-drawer height="100vh" id="app-drawer" absolute temporary v-model="navigation.drawer" :key="'nav-'+navigation.navDrawerKey">
          <v-toolbar color="primary" flat id="drawer-header"></v-toolbar>
          <v-list dense active-class="primary--text" class="pt-0 pb-5" :key="'list-'+navigation.listKey" id="drawer-list">
              <template v-for="item in navigation.items">
                  <v-list-item
                      :key="item.title+item.name"
                      :to="item.link"
                      :class="item.class"
                      v-model="item.show"
                      @click.stop="item.click()"
                      v-if="item.show"
                  >
                      <v-list-item-action >
                          <v-icon v-if="item.icon" :class="`${ item.color }--text`">{{ item.icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content :class="`${ item.color }--text`">
                          <v-list-item-title class="pl-2 text-left">{{ item.title }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="pa-0 ma-0" v-if="item.expandable">
                          <v-icon pa-0 ma-0 :class="`${ item.color }--text`">{{ item.expandable }}</v-icon>
                      </v-list-item-action>
                  </v-list-item>
              </template>
          </v-list>
      </v-navigation-drawer>
  </div>
</template>

<style>
.v-navigation-drawer__content::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px lightblue;
  background-color: lightblue;
}
.v-navigation-drawer__content::-webkit-scrollbar{
  width: 3px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb{
  -webkit-box-shadow: inset 0 0 6px #009688;
  background-color: #009688;
}
</style>

<script>
export default {
    name: "navigation",
    props:{
      navigation:{
        type: Object,
        required: true
      }
    },
    data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
      user: {},
    }),
    created(){
      this.devLog("NAV")
      this.user = JSON.parse(localStorage.getItem('userLogin'))
    }
}
</script>

<style>

</style>