export default {
    primary: "#f0243c",
    primary_dark: "#a81935",
    primary_light: "#ff93a8",
    secondary: "#f06224",
    accent: "#ffc9d4",

    error: "#EE0004",
    warning: "#FEC806",
    info: "#607d8b",
    success: "#4caf50",

    white: "fff",
    black: "000",

    viola_one: "#752092",
    viola_two: "#C957BC",
    viola_three: "#FFC872",
    viola_four: "#FFE3B3",

    seaside_one: "#26648E",
    seaside_two: "#4F8FC0",
    seaside_three: "#53D2DC",
    seaside_four: "#FFE3B3",

    cove_one: "#006BBB",
    cove_two: "#30A0E0",
    cove_three: "#FFC872",
    cove_four: "#FFE3B3",
}