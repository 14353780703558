import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$axios = axios

import Navigation from './components/navigation';
import FooterView from './components/footer';
import DataListPaginate from './components/data-list-paginate';
import VueTelInputVuetify from "vue-tel-input-vuetify";
import Breadcrumb from './components/breadcrumb';
import Confirm from './components/confirm-dialog';
import ErrorDialog from './components/error-dialog';
import LoadingDialog from './components/loading-dialog'
import AlertDialog from './components/alert-dialog'
import VuetifyMoney from './components/vuetify-money'
import * as VueGoogleMaps from 'vue2-google-maps'

import InfiniteScroll from 'v-infinite-scroll'


Vue.use(InfiniteScroll)

Vue.use(VueTelInputVuetify, {
  vuetify,
});

Vue.use(VueGoogleMaps, {
  load: {
    // key: 'AIzaSyAffcCPbpMCFO7SAr5lQ9gENiUzUgirAL0',//this is key from lks
    key: 'AIzaSyDkyrmhYBt6vT_aCxLv_d47iZoIoLwc_WA',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
 
    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})

Vue.mixin({
  data() {
      return {
        MENU: {
          PATH: null,
          INDEX: null,
        },

        currentYear: new Date().getFullYear(),

        global_rules:{
          not_null: [
            v => !!v || 'Field is required',
          ],
          nik: [
            v => !!v || 'Field is required',
            v => (!!v && v.length == 16) || 'NIK must be 16 digit',
          ],
          phone_nullable: [
            v => (!v || (!!v && v.length >= 9 && v.length <= 13) ) || 'Phone Number must between 9 ~ 13 digit',
          ],
          image: [
              value => !value || value.size < 2000000 || 'Avatar Profile size should be less than 2 MB!',
          ],
          select: [
              v =>  v.length > 0 || "Item is required in select 1",
          ],
          email: [
            v => !!v || 'Input is required',
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
          ],
          email_nullable: [
            v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
          ],
          number: [
            v => !!v || 'Field is required',
            v => (!!v && v != 0) || 'Minimum value is 1'
          ],
          lat: [
            v => !v || /^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?[0-8]\d((\.)|\.\d{1,6})?)|(0*?90((\.)|\.0{1,6})?))+$/.test(v) || 'Lat must be valid'
          ],
          long: [
            v => !v || /^(\+|-)?((\d((\.)|\.\d{1,6})?)|(0*?\d\d((\.)|\.\d{1,6})?)|(0*?1[0-7]\d((\.)|\.\d{1,6})?)|(0*?180((\.)|\.0{1,6})?))+$/.test(v) || 'Long must be valid'
          ]
        }
      }
  },
  components:{
    Navigation,FooterView,DataListPaginate,Breadcrumb,Confirm,ErrorDialog,
    LoadingDialog,AlertDialog,VuetifyMoney
  },
  beforeCreate(){

  },
  created(){

  },
  mounted(){

  },
  methods: {
    getDif(current, before){
        let result = 0;
        if(before == 0){
            if(current == 0) result = "0%"
            else result = "∞";
        }
        else{
            result = (Math.round((current-before)*100/before));
            if(result >= 0) result = "+"+result+"%";
            else result = result+"%";
        }

        return result;
    },
    numberFormat(data) {
        return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    highlightText(focus, text){
      if(focus && focus != ""){
        focus = focus.toUpperCase();
        text = text.toUpperCase();
        let arrText = text.split(focus);

        let result = "";

        arrText.forEach((t,i)=>{
          if(i != 0){
            result += "<b class='primary--text'>"+focus+"</b>";
          }
          result += t;
        });
        // this.devLog(result);
        return result;
      }

      return text.toUpperCase();
    },
    getDpt2019(query){
      return this.$root.getDpt2019(query);
    },
    getMedia(){
      return this.$root.getMedia();
    },
    getPolling(){
      console.log('eh');
      return this.$root.getPolling();
    },
    getPartai(){
      return this.$root.getPartai();
    },
    getPaslon(q = null){
      return this.$root.getPaslon(q);
    },
    getProvince(useR){
      return this.$root.getProvince(useR);
    },
    getCityRegency(pid){
      return this.$root.getCityRegency(pid);
    },
    getDistrict(cid){
      return this.$root.getDistrict(cid);
    },
    getSubdistrict(did){
      return this.$root.getSubdistrict(did);
    },

    getCityRegency2(pid){
      return this.$root.getCityRegency2(pid);
    },
    getDistrict2(cid){
      return this.$root.getDistrict2(cid);
    },
    getSubdistrict2(did){
      return this.$root.getSubdistrict2(did);
    },

    camelize(words) {
      var separateWord = words.toLowerCase().split(' ');
      for (var i = 0; i < separateWord.length; i++) {
          separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
          separateWord[i].substring(1);
      }
      return separateWord.join(' ');
    },
    formatPhone(code, text){
      let result = '('+code + ') ' + text.substring(0, 3)+ '-' + text.substring(3,7)+ '-' +text.substring(7);
      return result;
    },
    formatMoney(money){
      let val = (money/1).toFixed(0)//.replace('.', ',')
      return 'Rp ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getServiceText(item){
      return item.name+ " ("+(this.formatMoney(item.price))+"/"+(item.duration || 'session')+")"
    },
    getCurrentTime(){
      var today = new Date();
      today.setHours(today.getHours()+1);
      var time = today.getHours() + ":00";
      return time;
    },
    alert(title, message, options) {
      return this.$root.alert(title, message, options);
    },
    LOADING(dialog) {
      return this.$root.toggleLoading(dialog);
    },
    confirm(title, message, options) {
      return this.$root.confirm(title, message, options);
    },
    showErr(res, title="") {
      return this.$root.showErr(res, title);
    },
    getNameAvatar(value){
      if(value){
        let tamp = value;
        let matches = tamp.match(/\b(\w)/g);
        let name = matches.join('');

        return name.substring(0,2).toUpperCase();
      }
    },
    devLog(item){
      if ( process.env.NODE_ENV == 'development' ) {
          console.log(item);
      }
    },  
     
    async updateUserLogin(){
      this.devLog("USER TOKEN: "+ localStorage.token);
      // if(!localStorage.token){
        // this.getSessionToken();
      // }
      let print_page = false;
      this.devLog("CHECKING PATH on MAIN JS")
      if(window.location.pathname.includes('/print/order/invoice') || window.location.pathname.includes('/print/order/queue')){
        print_page = true;
        this.devLog("CHECKING RESULT: " + window.location.pathname)
      }else{
        this.devLog("CHECKING RESULT: " + window.location.pathname)
      }
      this.devLog(print_page);

      if(!print_page){
        try {
          const response = await axios.get(this.apiCore + "/verify-token", { headers: { 'Authorization': localStorage.token } })
          this.devLog("Hasil Check User: " + JSON.stringify(response.data))
          if (response.status == 200) {
            if (response.data.data && response.data.data.length > 0) {
              this.$root.userLogin = response.data.data[0]
              localStorage.userLogin = JSON.stringify(this.$root.userLogin)
              localStorage.akses = this.$root.userLogin.akses
              this.is_login = true
              this.$root.getMenus()
            } 
            // else {
            //   this.$root.userLogin = false
            //   this.$root.is_login = false
            // }
          }
        } catch {
          this.devLog('NOT LOGGED!!!!')
          this.$root.userLogin = {}
          this.$root.is_login = false
          localStorage.clear();
          this.alert("Alert", "Token Expired, please relogin!", { color: 'warning', close:this.relogin })
          // this.$router.go()
        }
      }else{
        this.is_login = true
        this.$root.is_login = true
      }
    },

    relogin(){
      this.$router.go();
    },

    getSessionToken(){
      axios.get(this.apiBooking+"/get-session-token", { headers: { } })
      .then(response => {
          this.devLog("Hasil Check Token: " +JSON.stringify(response.data));
          if(response.status == 200){
            localStorage.token = response.data
          }
      }).catch((err) => {
          if(err.response){
              this.showErr(err.response,"Failed");
          }
          else{
              this.showErr({status:"Code Error", statusText: err});
          }
      });
    },

    getRoles(){
      this.devLog("USER TOKEN: "+ localStorage.token);
      return axios.get(this.API+"/roles", { headers: { 'Authorization': localStorage.token } });
    },

    /**
       * SNACKBAR options
       * @param text               - SNACKBAR text
       * @param options.buttonText - Button text
       * @param options.closeable  - Whether SNACKBAR is closeable
       * @param options.onClick    - Button handler
       * @param options.timeout    - SNACKBAR timeout
       * @param options.type       - SNACKBAR type
       */
     addNotification(text, options = {}) {
      // Hide previous notification
      // this.devLog("SNACKBAR Notif: "+ this.$root.snackbar.notification);
      this.$root.snackbar.notification = false;

      // Display new notification (but give time for previous to disthis.$rootear)
      setTimeout(() => {
          this.$root.snackbar.notificationText = text;
          this.$root.snackbar.notification = true;
          // this.devLog("SNACKBAR Notif 3: "+ this.$root.snackbar.notification);
          // Customization
          this.$root.snackbar.buttonColor = options.type ? 'black' : "primary";
          this.$root.snackbar.buttonText = options.buttonText ? options.buttonText : "";
          this.$root.snackbar.hasButton = options.buttonText && options.onClick;
          this.$root.snackbar.onClick = options.onClick ? options.onClick : function(){};
          this.$root.snackbar.timeout = options.timeout ? options.timeout : 2000;
          this.$root.snackbar.type = options.type ? options.type : "secondary";

          // Closeable SNACKBARs override other properties
          if (options.closeable) {
              this.$root.snackbar.timeout = 5000;
              this.$root.snackbar.buttonText = "Tutup";
              this.$root.snackbar.buttonColor = "success";
              this.$root.snackbar.hasButton = true;
              this.$root.snackbar.onClick = this.closeNotification;
          }
      }, 100)
      // this.devLog("SNACKBAR Notif 2: "+ this.$root.snackbar.notification);
  },
  /**
   * Close the SNACKBAR and execute callback
   */
  clickHandler(callback) {
      this.closeNotification();
      callback();
  },
  /**
   * Close the SNACKBAR
   */
  closeNotification() {
      this.$root.snackbar.notification = false;
  },

  simpleSnackbar(text, type) {
      this.addNotification(text, {type: type})
  },
  closeableSnackbar(text) {
      this.addNotification(text, { closeable: true });
  },
  complexSnackbar(text,olddata,api,fx) {
      const options = {
          buttonText: "Pulihkan",
          onClick: () => {
              axios.put(api,olddata,{ headers: { 'Authorization': localStorage.token } })
              .then(response => {
                  // this.devLog(JSON.stringify(response));
                  if(response.status == 201 && response.data.id){
                      this.closeableSnackbar("Data terhapus telah berhasil dipulihkan")
                      fx();
                  }else{
                      this.simpleSnackbar("Sorry, we've failed to undo your previous action!","danger");
                      this.showErr(response);
                  }
              }).catch((err) => {
                  if(err.response){
                      this.showErr(err.response,"Failed");
                  }
                  else{
                      this.showErr({status:"Code Error", statusText: err});
                  }
              });

          },
          timeout: 5000,
          type: "success"
      };
      this.addNotification(text, options);
  },
  },
});

var myApp = new Vue({
  vuetify,
  router,
  render: h => h(App),

  data(){
    return{
      print_invoice_model: [],
      is_printing: false,
      err_dialog: {show: false, text: "", status: 0},
      loading_dialog: false,
      is_login: false,
      userLogin: false,
      isExpand: [],
      expandIcon: "expand_more",
      navigation:{
        drawer: false,
        items: [],
        triggered: false,
        need_trigger: null,
        active_parent_idx: null,
        expandIcon: "expand_more",
        refreshKey: 0,
        toolbarKey: 0,
        navDrawerKey: 0,
        listKey: 0,
      },
      isWebview: false,
      triggered: false,
      need_trigger: null,
      active_parent_idx: null,
      dynamic_menus: [],
      snackbar: null,

      province_list: [],
    }
  },

  async created(){
    this.devLog("App Component created...");
    this.updateSnackBar();
    
    this.devLog("Checking Local Token...");
    if( !localStorage.getItem('token') ){
        this.devLog("Local Token Not Exist, Initializing...");
        this.is_login = false
    }else{
        this.is_login = true;
    }
    this.initData();
  },

  mounted(){
    this.confirm = this.$children[0].$refs.confirm.open;
    this.alert = this.$children[0].$refs.alert.open;
  },

  methods:{
    async getDpt2019(query){
      // this.toggleLoading(true);
      let result = [];
      await this.$axios.get(this.apiCore+'/dpt-2019/paginate?'+query, {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
        if(response.status == 200){
            this.devLog("DPT 2019 ====");
            this.devLog(response.data);
            result = response.data;
        }
      }).finally(()=>{
        // this.toggleLoading(false);
        return result;
      });

      return result;
    },

    async getMedia(){
      this.toggleLoading(true);
      let result = [];
      await this.$axios.get(this.apiCore+'/medias', {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
        if(response.status == 200){
            this.devLog("Media ====");
            this.devLog(response.data);
            result = response.data;
        }
      }).finally(()=>{
        this.toggleLoading(false);
        return result;
      });

      return result;
    },

    async getPolling(){
      this.toggleLoading(true);
      let result = [];
      await this.$axios.get(this.apiCore+'/pollings', {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
        if(response.status == 200){
            this.devLog("Polling ====");
            this.devLog(response.data);
            result = response.data;
        }
      }).finally(()=>{
        this.toggleLoading(false);
        return result;
      });

      return result;
    },

    async getPartai(){
      this.toggleLoading(true);
      let result = [];
      await this.$axios.get(this.apiCore+'/partais', {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
        if(response.status == 200){
            this.devLog("Partai ====");
            this.devLog(response.data);
            result = response.data;
        }
      }).finally(()=>{
        this.toggleLoading(false);
        return result;
      });

      return result;
    },

    async getPaslon(q = null){
      this.toggleLoading(true);
      let result = [];
      let url = this.apiCore+'/paslons?';
      if(q){
        url += q;
      }
      await this.$axios.get(url, {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
        if(response.status == 200){
            this.devLog("Paslons ====");
            this.devLog(response.data);
            result = response.data;
        }
      }).finally(()=>{
        this.toggleLoading(false);
        return result;
      });

      return result;
    },

    async getProvinceByRegion(useR){
      this.toggleLoading(true);
      let rid = this.userLogin.region_id;
      if(!useR){
        rid = 0;
      }

      rid=0;
      return this.$axios.get(this.apiCore+'/provinsi/region_id/'+rid, {
          headers: {
              'Authorization': localStorage.token,
          }
      });
    },

    async getCityRegenciesByProvince(pid){
      this.toggleLoading(true);
      return this.$axios.get(this.apiCore+'/regencies?province_id='+pid, {
          headers: {
              'Authorization': localStorage.token,
          }
      });
    },

    async getDistrictByCityRegency(cid){
      this.toggleLoading(true);
      return this.$axios.get(this.apiCore+'/districts?city_regency_id='+cid, {
          headers: {
              'Authorization': localStorage.token,
          }
      });
    },

    async getSubdistrictByDistrict(cid){
      this.toggleLoading(true);
      return this.$axios.get(this.apiCore+'/subdistricts?district_id='+cid, {
          headers: {
              'Authorization': localStorage.token,
          }
      });
    },

    async getProvince(useR = true){  
      await this.getProvinceByRegion(useR).then(response => {
        if(response.status == 200){
            this.devLog("Province ====");
            this.devLog(response.data);
            localStorage.setItem('province_list', JSON.stringify(response.data))
            this.province_list = response.data;
        }
      }).finally(()=>{
        this.toggleLoading(false);
        return this.province_list;
      });
    },
    
    async getCityRegency(pid = null){
      if(!localStorage.getItem('cr_of_'+pid)){
        let tamp = [];
        await this.getCityRegenciesByProvince(pid).then(response => {
          if(response.status == 200){
              this.devLog("City/ Regency ====");
              this.devLog(response.data);
              localStorage.setItem('cr_of_'+pid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('cr_of_'+pid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('cr_of_'+pid));
      }
    },

    async getDistrict(cid = null){
      if(!localStorage.getItem('district_of_'+cid)){
        let tamp = [];
        await this.getDistrictByCityRegency(cid).then(response => {
          if(response.status == 200){
              this.devLog("District ====");
              this.devLog(response.data);
              localStorage.setItem('district_of_'+cid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('district_of_'+cid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('district_of_'+cid));
      }
    },

    async getSubdistrict(cid = null){
      if(!localStorage.getItem('subdistrict_of_'+cid)){
        let tamp = [];
        await this.getSubdistrictByDistrict(cid).then(response => {
          if(response.status == 200){
              this.devLog("Subistrict ====");
              this.devLog(response.data);
              localStorage.setItem('subdistrict_of_'+cid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('subdistrict_of_'+cid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('subdistrict_of_'+cid));
      }
    },

    async getCityRegency2(pid = null){
      if(!localStorage.getItem('cr2_of_'+pid)){
        let tamp = [];
        await this.getCityRegenciesByProvince(pid).then(response => {
          if(response.status == 200){
              this.devLog("City/ Regency 2====");
              this.devLog(response.data);
              localStorage.setItem('cr2_of_'+pid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('cr2_of_'+pid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('cr2_of_'+pid));
      }
    },

    async getDistrict2(cid = null){
      if(!localStorage.getItem('district2_of_'+cid)){
        let tamp = [];
        await this.getDistrictByCityRegency(cid).then(response => {
          if(response.status == 200){
              this.devLog("District 2====");
              this.devLog(response.data);
              localStorage.setItem('district2_of_'+cid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('district2_of_'+cid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('district2_of_'+cid));
      }
    },

    async getSubdistrict2(cid = null){
      if(!localStorage.getItem('subdistrict2_of_'+cid)){
        let tamp = [];
        await this.getSubdistrictByDistrict(cid).then(response => {
          if(response.status == 200){
              this.devLog("Subistrict 2====");
              this.devLog(response.data);
              localStorage.setItem('subdistrict2_of_'+cid, JSON.stringify(response.data))
          }
        }).finally(()=>{
          this.toggleLoading(false);
          tamp = JSON.parse(localStorage.getItem('subdistrict2_of_'+cid));
          return tamp;
        });
        return tamp;
      }else{
        return JSON.parse(localStorage.getItem('subdistrict2_of_'+cid));
      }
    },

    changeIsWebview(value){
      this.isWebview = value;
    },
    checkLogin(){
      return this.is_login;
    },
    async initData() {
      this.devLog("checking login data");

      let specialLogin = window.location.href.includes('wv/AIzaSyDkyrmhYBt6vT_aCxLv_d47iZoIoLwc_WA');

      if(localStorage.getItem('userLogin') || specialLogin){
          this.devLog("Logged, continuing...");
          this.userLogin = JSON.parse( localStorage.getItem('userLogin') );
          this.is_login = true
          this.getMenus();
      }else{
          this.is_login = false
          this.userLogin = false;
          this.devLog("Not Logged, redirecting...");
          //this.$router.go();
      }
    },
    
    getMenus(){
      this.$axios.get(this.apiCore+'/menu/role', {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
          if(response.status == 200){
              this.devLog("MENU ====");
              this.devLog(response.data);
              this.dynamic_menus = response.data;
          }
      }).finally(()=>{
        this.updateItems();
        this.updateDrawer();
      })
    },

    renderDinamycMenu(){
      this.navigation.items = [];
      this.dynamic_menus.forEach(m => {
          let x = Object.assign({},m);
          
          if(m.slug){
            x.link = null;
            let split_slug = m.slug.split('/');
            if(split_slug[0] == this.appName && m.subs_app_id == this.appID){//This is local menu
                split_slug.splice(0,1);
                x.link = "/"+split_slug.join('/');
            }else{
                x.link = "/"+m.slug;
            }
          }
          
          if(x.children.length){
            x.expandable= this.expandIcon;
            if(x.parent_id){
              x.haveChild = true;
            }
          }
          if(x.parent_id){
            x.expandItem = true;
          }
          if(x.is_grand_child){
            x.isGrandChild = true;
          }
          x.show = true;
          x.click = function(){};

          this.navigation.items.push(x)
      })
      this.devLog("====== this.devLog(this.navigation.items); =======");
      this.devLog(this.navigation.items);
    },

    updateItems(){
      if(this.dynamic_menus.length){
        this.renderDinamycMenu();
      }else{
        this.navigation.items = [
          // { title: 'Dashboard', icon: 'dashboard', name:'dashboard', link: "/dashboard", class:"pl-0", show: true, click:function(){}},
          { title: 'Staff', icon: 'dashboard', name:'staff', link: "/staff", class:"pl-0", show: true, click:function(){}},
          { title: 'Service & Product', icon: 'dashboard', name:'service-product', link: "/service-product", class:"pl-0", show: true, click:function(){}},
          { title: 'Booking', icon: 'dashboard', name:'booking', link: "/booking", class:"pl-0", show: true, click:function(){}},
          { title: 'Schedule', icon: 'dashboard', name:'schedule', link: "/schedule", class:"pl-0", show: true, click:function(){}},
          
          { title: 'Setting', icon: 'settings_applications', name:'setting',class:"menu pl-0", expandable:this.expandIcon, click:function(){}, show: true},
            { title: 'Booking', icon: 'auto_awesome_motion', class:"pl-6", name:'profile', haveChild:true, expandable:this.expandIcon, expandItem: true, click:function(){}, show: true},
                { title: 'Additional Field', icon: 'room_preferences', name:'eav', link: "/setting/eav", isGrandChild:true, expandItem: true, class:"pl-12", show: true, click:function(){} },
            { title: 'Loyalty', icon: 'settings_accessibility', name:'users', class:"pl-6", haveChild:true, expandable:this.expandIcon, expandItem: true, click:function(){}, show: (!!this.role && this.role.id == "1")},
            { title: 'Order', icon: 'auto_awesome_motion', name:'users', class:"pl-6", haveChild:true, expandable:this.expandIcon, expandItem: true, click:function(){}, show: (!!this.role && this.role.id == "1")},
                
            { title: 'General', icon: 'admin_panel_settings', expandItem: true, name:'general-setting', class:"pl-6", show: (!!this.role && this.role.id == "1"), click: ()=>{ this.alert('Oops!',"Nothing's Here!",{bg_color:'warning', color: 'black'});} },
            
        ]
      }

      this.navigation.items.push({ title: 'Log Out', icon: 'exit_to_app', childs: null, name:'logout',  class:"hidden-sm-and-up", show: true, click:this.logout })
    },


    triggerClickExpand(){
      if(this.triggered && this.need_trigger != null){
          this.devLog("TRIGGERRED!!");
          this.triggered = false;
          this.navigation.items[this.need_trigger].click();
          this.need_trigger = null;
      }
    },
    
    updateSnackBar(){
      this.snackbar = {
          buttonColor: "",
          buttonText: "",
          hasButton: true,
          notificationText: "",
          notification: false,
          timeout: 5000,
          type: null,
          onClick: function(){},
      };
    },
    updateDrawer(){
      let path = this.$route.path;
      this.isExpand = [];
      // let lastExpand = null;
      // let lastIdx = null;
      // this.navigation.items.forEach((menu) => {
      //     if(!menu.click){
      //         menu.click = ()=>{
      //             this.devLog("menu click");
      //             this.drawer = false;
      //             this.updateDrawer();
      //         };
      //     }

      //     if(menu.link == path || (menu.link == '/home' && path=='/') ){
      //         this.devLog(menu.link);
      //         this.devLog(path);
      //         menu.class = "tab-selected pl-6";
      //     }else{
      //         let test = path.split('/');
      //         test.pop();

      //         menu.class = "pl-6";
      //         while(test.length >= 2 && menu.link != test.join("/")){
      //             test.pop();
      //         }
      //         if(menu.link && menu.link == test.join("/") && test.join("/") != ''){
      //             this.devLog(test);
      //             menu.class = "tab-selected pl-6";
      //         }
      //     }
      // });

      let lastExpand = null;
      let lastExpandChild = null;
      // let lastIdx = null;
      this.active_parent_idx = null;
      this.navigation.items.forEach((menu, index) => {
          if(menu.expandable){
              menu.expandable = "expand_more";

              if(menu.class != "hidden-sm-and-up") {
                  menu.class = "menu pl-2";
              }
              var idx = this.isExpand.push(false) - 1;
              if(menu.haveChild){
                  menu.click = ()=>{
                      this.toggleGrandExpand(index, idx);
                  };
              }else{
                  menu.click = ()=>{
                      this.toggleExpand(index, idx);
                  };
              }
              menu.expandIdx = idx;
              if(menu.haveChild){
                  lastExpandChild = index;
              }else{
                  lastExpand = index;
              }
              // lastIdx = idx;
          }
          if(menu.expandItem){
              menu.expandItem = index;

              menu.show = this.isExpand[this.isExpand.length-1] && !menu.hide;

              if(menu.link == path || (menu.link == '/dashboard' && path=='/') || (menu.link == '/dashboard' && path=='/home') ){
                  if(menu.isGrandChild){
                      menu.class = "pl-10 tab-selected";
                  }else{
                      menu.class = "pl-6 tab-selected";
                  }
              }else{
                  let test = path.split('/');
                  test.pop();
                  menu.class = menu.isGrandChild ? "pl-10" : "pl-6";
                  while(test.length >= 2 && menu.link != test.join("/")){
                      test.pop();
                  }
                  if(menu.link == test.join("/")){
                      menu.class = menu.class + " tab-selected";
                  }
              }

              if(menu.class == "pl-6 tab-selected" || menu.class == "pl-10 tab-selected"){
                  if(menu.class == "pl-6 tab-selected"){
                      this.active_parent_idx = lastExpand;
                  }else{
                      this.active_parent_idx = lastExpandChild;
                  }

                  if(!menu.show && !menu.hide){
                      this.need_trigger = this.active_parent_idx;
                      this.triggered = true;
                  }
              }
          }else{
              if(menu.link == path || (menu.link == '/dashboard' && path=='/') || (menu.link == '/dashboard' && path=='/home') ){
                  menu.class = "menu-selected pl-2";
              }else{
                  let test = path.split('/');
                  test.pop();
                  if(menu.class != "hidden-sm-and-up") {
                      menu.class = "menu pl-2";
                  }
                  while(test.length >= 2 && menu.link != test.join("/")){
                      test.pop();
                  }
                  if(menu.link == test.join("/")){
                      menu.class = "menu-selected pl-2";
                  }
              }
          }
      });
      this.triggerClickExpand();
    }, 

    // toggleExpand(itemIdx, idx){
    //   this.devLog(itemIdx)
    //   this.devLog(idx)
    //     this.isExpand[idx] = !this.isExpand[idx];

    //     this.navigation.items[itemIdx].expandable = (this.isExpand[idx]) ? "expand_less" : "expand_more";
    //     this.navigation.items[itemIdx].class = (this.isExpand[idx]) ? "menu-selected" : "menu";
    //     let start = itemIdx +1;
    //     while (!!this.navigation.items[start] && !this.navigation.items[start].expandable && start < this.navigation.items.length-1) {
    //         // this.devLog(this.navigation.items[start]);
    //         this.navigation.items[start].show = (this.isExpand[idx] && (!this.navigation.items[start].hide) )
    //         start++;
    //     }
    //     this.devLog("toggling Index: "+idx);
    // },

    toggleExpand(itemIdx, idx){
      this.devLog("toggleExpand CALLED")
      this.isExpand[idx] = !this.isExpand[idx];
      this.navigation.items[itemIdx].expandable = (this.isExpand[idx]) ? "expand_less" : "expand_more";
      this.navigation.items[itemIdx].class = (this.isExpand[idx]) ? "menu-selected pl-2" : "menu pl-2";
      let start = itemIdx +1;

      while (!!this.navigation.items[start] && this.navigation.items[start].expandItem && start < this.navigation.items.length-1) {
          if(!this.navigation.items[start].isGrandChild || !(this.isExpand[idx] && (!this.navigation.items[start].hide) )){
              this.navigation.items[start].show = (this.isExpand[idx] && (!this.navigation.items[start].hide) )
              //Un-expand submenu if have Child on menu un-expanded

              if(this.navigation.items[start].haveChild && !this.navigation.items[start].show){
                  this.navigation.items[start].expandable = "expand_more";
                  this.navigation.items[start].class = "pl-6";
                  this.isExpand[this.navigation.items[start].expandIdx] = false;
                  this.devLog("===== 01 =====")
              }
              //Expand submenu if have child
              if(this.navigation.items[start].haveChild && this.navigation.items[start].show && this.active_parent_idx == start){
                  if(this.navigation.items[start].expandable == "expand_more"){
                      this.triggered = true
                      this.need_trigger = start;
                      this.devLog("===== 02 =====")
                  }
              }
          }
          start++;
      }

      this.triggerClickExpand();
    },

    toggleGrandExpand(itemIdx, idx){
        this.devLog("toggleGrandExpand CALLED")
        this.isExpand[idx] = !this.isExpand[idx];

        this.navigation.items[itemIdx].expandable = (this.isExpand[idx]) ? "expand_less" : "expand_more";
        this.navigation.items[itemIdx].class = (this.isExpand[idx]) ? "tab-selected pl-6" : "pl-6";

        let start = itemIdx +1;
        while (!!this.navigation.items[start] && this.navigation.items[start].isGrandChild && start < this.navigation.items.length-1) {
            this.navigation.items[start].show = (this.isExpand[idx] && (!this.navigation.items[start].hide) )
            start++;
        }

        //Seek parent menu
        let end = itemIdx;
        while( (this.navigation.items[end].class != "menu pl-2" && this.navigation.items[end].class != "menu-selected pl-2") && end > 0){
            end--;
        }
        if(!this.navigation.items[end].hide && this.navigation.items[end].class == "menu pl-2"){
            this.triggered = true;
            this.need_trigger = end
        }

        this.triggerClickExpand();
    },

    logout() {
      this.navigation.drawer = false;
      this.$axios.get(this.apiCore+'/logout', {
          headers: {
              'Authorization': localStorage.token,
          }
      }).then(response => {
          if(response.status == 200){
              this.devLog("LOGOUT SUKSES");
          }
      }).finally(()=>{
        if(localStorage.userLogin){
          localStorage.clear();
          this.$root.userLogin = {}
          this.is_login = false;
          this.$router.go();
        }
      })
    },
    showErr(res, title) {
      console.log('showErr called');

      if(!res.config){
          res.config = {
              url: "-",
              method:'-',
          }
      }

      switch (title) {
          case "Failed":
              this.err_dialog= {
                  show : true,
                  title: title,
                  subtitle: res.data.message || res.message,
                  message: "("+res.status+": "+res.statusText+")",
                  customClose: res.customClose ? res.customClose: (res.status == 401? () => this.$router.push('/dashboard') : null),
              }
              break;
          case "Warning":
              this.err_dialog= {
                  show : true,
                  title: title,
                  type: "warning",
                  subtitle: res.data.message,
                  // message: "("+res.config.url+")",
                  customClose: res.customClose ? res.customClose: null,
              }
              break;
          case "Error":
              var sub = "";
              var msg = "";
              var resdat = null;
              if(res.response && res.response.data){
                  resdat = res.response.data;
                  if(resdat.exception){
                      sub = resdat.exception;
                  }
                  if(resdat.file){
                      //sub += " at "+ resdat.file;
                  }

                  if(resdat.message){
                      msg = resdat.message;
                  }
              }else{
                  msg = res;
                  sub = "Code Error";
              }

              this.err_dialog= {
                  show : true,
                  title: title,
                  subtitle: sub,
                  message: msg,
                  customClose: res.customClose ? res.customClose: null,
              }
          break;

          default:
              this.err_dialog= {
                  show : true,
                  title: title,
                  subtitle: res.status,
                  message: res.statusText+"\r\n("+ res.config.method.toUpperCase() + ": "+res.config.url+")",
                  customClose: res.customClose ? res.customClose: null,
              }
          break;
        }
    },
    toggleLoading(dialog){
      this.loading_dialog = dialog;
    },
  }
}).$mount('#app')

if(myApp){
  console.log('App Initialized');
}

