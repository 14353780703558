var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"flat":"","tile":"","top":""}},[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({staticClass:"white--text",on:{"click":function($event){$event.stopPropagation();return (function (){_vm.navigation.drawer = !_vm.navigation.drawer; _vm.$root.updateDrawer();}).apply(null, arguments)}}},on))]}}])},[_c('span',[_vm._v("Menu")])]),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"white--text pr-3 font-weight-bold"},[_vm._v(_vm._s(_vm.$root.userLogin.name))]),_c('span',{staticClass:"text-xs-center"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"nudge-bottom":12,"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"color":"primary lighten-2"}},'v-avatar',attrs,false),Object.assign({}, tooltip, menu)),[(_vm.$root.userLogin.avatar != null)?_c('img',{attrs:{"src":_vm.$root.userLogin.avatar != null ? _vm.$root.userLogin.avatar : ''}}):_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.getNameAvatar(_vm.$root.userLogin.name)))])])]}}],null,true)},[_c('span',[_vm._v("Profile")])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-card-text',{staticClass:"accent--text text--lighten-0",staticStyle:{"background-color":"var(--v-primary-darken4)"}},[_c('v-avatar',{attrs:{"color":"primary lighten-2","width":"100","height":"100"}},[(_vm.$root.userLogin.avatar != null)?_c('img',{attrs:{"src":_vm.$root.userLogin.avatar != null ? _vm.$root.userLogin.avatar : 'https://cdn.vuetifyjs.com/images/john.jpg'}}):_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.getNameAvatar(_vm.$root.userLogin.name)))])]),_c('div',{staticClass:"pt-2"},[_c('b',[_vm._v(_vm._s(_vm.user.name))]),_c('br'),_c('b',[_vm._v(_vm._s(_vm.user.role.name))])])],1),_c('v-divider'),_c('v-card-actions',[_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":"/profile"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){_vm.menu = false}}},on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("person")]),_vm._v(" Profile ")],1)]}}])},[_c('span',[_vm._v("Profile")])])],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.$root.logout()}}},Object.assign({}, tooltip, _vm.menu)),[_c('v-icon',[_vm._v("exit_to_app")])],1)]}}])},[_c('span',[_vm._v("Log Out")])])],1)],1)],1)],1)],1)],1),_c('v-navigation-drawer',{key:'nav-'+_vm.navigation.navDrawerKey,attrs:{"height":"100vh","id":"app-drawer","absolute":"","temporary":""},model:{value:(_vm.navigation.drawer),callback:function ($$v) {_vm.$set(_vm.navigation, "drawer", $$v)},expression:"navigation.drawer"}},[_c('v-toolbar',{attrs:{"color":"primary","flat":"","id":"drawer-header"}}),_c('v-list',{key:'list-'+_vm.navigation.listKey,staticClass:"pt-0 pb-5",attrs:{"dense":"","active-class":"primary--text","id":"drawer-list"}},[_vm._l((_vm.navigation.items),function(item){return [(item.show)?_c('v-list-item',{key:item.title+item.name,class:item.class,attrs:{"to":item.link},on:{"click":function($event){$event.stopPropagation();return item.click()}},model:{value:(item.show),callback:function ($$v) {_vm.$set(item, "show", $$v)},expression:"item.show"}},[_c('v-list-item-action',[(item.icon)?_c('v-icon',{class:((item.color) + "--text")},[_vm._v(_vm._s(item.icon))]):_vm._e()],1),_c('v-list-item-content',{class:((item.color) + "--text")},[_c('v-list-item-title',{staticClass:"pl-2 text-left"},[_vm._v(_vm._s(item.title))])],1),(item.expandable)?_c('v-list-item-action',{staticClass:"pa-0 ma-0"},[_c('v-icon',{class:((item.color) + "--text"),attrs:{"pa-0":"","ma-0":""}},[_vm._v(_vm._s(item.expandable))])],1):_vm._e()],1):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }