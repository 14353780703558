import Vue from 'vue';
import light from './theme'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
    theme: {
        themes: { light },
        options: { customProperties: true },
    }
}

export default new Vuetify(opts);
